// store/index.js
import {createStore} from 'vuex'
import {api_GET} from './api'


import {network} from "@/stores/network";
import axios from "axios";

//import { makeServer } from './server'

function countCommonValues(arr1, arr2) {
  let count = 0;
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      count++;
    }
  }
  if (count > 0) {
    return true;
  } else {
    return false;
  }
}

const token = localStorage.getItem('authToken')
if (token) {
  axios.defaults.headers.common['Authorization'] = `token ${token}`
}

const store = createStore({
  state() {
    return {
      user: null,
      message: {
        open: false,
        to: [],
        text: '',
      },
      //years : [],
      current_edit: null,
      selected_period: {},
      selected_branche: {},
      selected_branche_period: {},
      current_branche: {},
      current_subscription: {
        child: {},
        group: {},
        cs: null
      },
      childSubscriptions: {
        name: null,
        results: []
      },
      memberSubscriptions: {
        name: null,
        results: []
      },
      memberChildren : {
        name: null,
        results: []
      },
      notAvailableTxt: 'LOADING...',
    }
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user

      if( user )
      {
        user.caps.forEach(cap => {
          state.user['is_'+cap] = true
        });
      }
      // localStorage.setItem('user', JSON.stringify(user))
    },
    SET_YEARS(state, years) {
      state.years = years
    },
    SET_PERIODS(state, data) {
      const year = state.years.find(year => year.id === data.id);
      year.periods = data.periods
    },
    SET_HOLIDAYS(state, data) {
      const year = state.years.find(year => year.id === data.id);
      year.holidays = data.holidays
    },
    INSERT_RELATION(state, args) {
      let resultTemp = state[args.type].results.find(obj => obj.id === args.id);
      resultTemp[args.relation] = args.relationData
    },
    UPDATE_DATA(state, args) {
      let resultTemp = state[args.type].results.find(obj => obj.id === args.data.id);
      if (!resultTemp) {
        if (!Array.isArray(state[args.type].results)) {
          state[args.type].results = []
        }
        state[args.type].results.push(args.data);
      } else {
        console.log('store update', state, args.data);
        Object.assign(resultTemp, args.data)
      }
      state[args.type] = Object.assign({}, state[args.type])
    },
    SET_EDIT(state, obj) {
      console.log('edit', obj);
      state.current_edit = obj
    },
    setSubscriptionSelection(state, obj) {
      state.current_subscription = obj
    }
  },

  actions: {
    async doRegister({dispatch}, userData) {
      await dispatch('network/post', {
        'url': '/dj-rest-auth/registration/',
        'data': userData
      }, {root: true})
    },

    async doLogin({dispatch, commit}, {email, password}) {
      const credentials = await dispatch('network/post', {
        'url': '/dj-rest-auth/login/',
        'data': {email, password}
      }, {root: true})

      if (process.env.NODE_ENV === 'development') {
        commit('network/setAuthToken', credentials.key, {root: true})
        axios.defaults.headers.common['Authorization'] = `Token ${credentials.key}`
      }

      commit('SET_USER', credentials.user)

      //reload data after login, admins/coords could have more visibility
      dispatch('loadGlobalData')
    },

    async doLogout({commit, dispatch}) {
      if (process.env.NODE_ENV === 'development') {
        console.log('deleting auth token')
        delete axios.defaults.headers.common["Authorization"];
        commit('network/setAuthToken', '', {root: true})
      } else {
        await dispatch('network/post', {
          'url': '/dj-rest-auth/logout/'
        }, {root: true})
      }
      commit('SET_USER', null)
    },

    async fetchUserData({dispatch, commit}) {
      //if(!this.state.user) return
      console.log('fetchUserData')
      try {
        const user = await dispatch('network/get', {
          'url': '/api/users/me/'
        }, {root: true})
        await commit('SET_USER', user)
        console.log("commited user")
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.warn('unable to fetch user data, probably not logged in')
          dispatch('doLogout')
        }
      }
    },

    async loadGlobalData({dispatch, getters}) {
      dispatch('fetch', {
        type: 'data/subjects',
      });
      dispatch('fetch', {
        type: 'data/levels',
      });

      dispatch('fetch', {
        type: 'data/provinces',
        filter: {
          visible: true
        }
      });

      dispatch('fetch', {
        type: 'years',
        relations: ['periods', 'holidays'],
        relName: 'year'
      });

      dispatch('fetch', {
        type: 'periods',
        filter: {
          visible: true
        }
      });

      if (getters.isLoggedIn) {
        dispatch('fetch', {
          type: 'children',
          filter: {
            visible: true
          }
        });
      }
    },

    async listYears({dispatch}) {
      return await dispatch('network/get', {
        'url': '/api/years/',
      }, {root: true})
    },

    async listPeriods({dispatch}) {
      return await dispatch('network/get', {
        'url': '/api/periods/',
      }, {root: true})
    },

    async listBranches({dispatch}, periodId) {
      const url = periodId ? `/api/branches/?period=${periodId}` : '/api/branches/'
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async listBranchesPeriod({dispatch}, periodId) {
      const url = periodId ? `/api/branch_periods/?period=${periodId}` : '/api/branch_periods/'
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async updateBranchPeriod({dispatch}, branchPeriodData) {
      return await dispatch('network/patch', {
        'url': `/api/branch_periods/${branchPeriodData.id}/`,
        'data': branchPeriodData
      }, {root: true})
    },

    async listChildren({dispatch}, parentId) {
      const url = parentId ? `/api/children/?parent=${parentId}` : '/api/children/'
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async listChildrenNoSubs({dispatch}, parentId) {
      const url = parentId ? `/api/children/nosubs/?parent=${parentId}` : '/api/children/nosubs/'
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async patchChild({dispatch}, childData) {
      return await dispatch('network/patch', {
        'url': `/api/children/${childData.id}/`,
        'data': childData
      }, {root: true})
    },

    async getUser({dispatch}, userId) {
      return await dispatch('network/get', {
        'url': `/api/users/${userId}/`,
      }, {root: true})
    },

    async getBranch({dispatch}, branchId) {
      const url = `/api/branches/${branchId}/`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async getBranchPeriod({dispatch}, branchPeriodId) {
      const url = `/api/branch_periods/${branchPeriodId}/`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async getPaymentRequest({dispatch}, prId) {
      const url = `/api/finance/subscriptions/${prId}/`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async deletePaymentRequest({dispatch}, prId) {
      const url = `/api/finance/subscriptions/${prId}/`
      return await dispatch('network/delete', {
        'url': url,
      }, {root: true})
    },

    async markPaymentRequestPaid({dispatch}, prId) {
      const url = `/api/finance/subscriptions/${prId}/force_paid/`
      return await dispatch('network/post', {
        'url': url,
      }, {root: true})
    },

    async paymentRequestRefund({dispatch}, data) {
      const url = `/api/finance/subscriptions/${data.prId}/refund/`
      return await dispatch('network/post', {
        'url': url,
        data
      }, {root: true})
    },

    async listBranchRevenue({dispatch}, params) {
      const url = `/api/finance/reports/`
      return await dispatch('network/get', {
        'url': url,
        'params': params
      }, {root: true})
    },

    async getBranchRevenueDetail({dispatch}, branchId) {
      const url = `/api/finance/reports/${branchId}/`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async duplicateBranches({dispatch}, {branchIds, periodId, withTeachers}) {
      const url = `/api/branch_periods/copy/`
      return await dispatch('network/post', {
        'url': url,
        'data': {branchIds, periodId, withTeachers}
      }, {root: true})
    },

    async deleteParent({dispatch}, parentId) {
      const url = `/api/users/${parentId}/`
      return await dispatch('network/delete', {
        'url': url,
      }, {root: true})
    },

    async deleteChildren({dispatch}, childId) {
      const url = `/api/children/${childId}/`
      return await dispatch('network/delete', {
        'url': url,
      }, {root: true})
    },

    async deleteSubscription({dispatch}, subId) {
      const url = `/api/subscriptions/${subId}/`
      return await dispatch('network/delete', {
        'url': url,
      }, {root: true})
    },

    async listGroups({dispatch}, branchPeriodId) {
      const url = branchPeriodId ? `/api/groups/?branch_period=${branchPeriodId}` : '/api/groups/'
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async getGroup({dispatch}, groupId) {
      const url = `/api/groups/${groupId}/`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async listSubscriptions({dispatch}, params) {
      const url = `/api/subscriptions/`
      return await dispatch('network/get', {
        'url': url,
        'params': params
      }, {root: true})
    },

    async listSessions({dispatch}, params) {
      const url = `/api/sessions/`
      return await dispatch('network/get', {
        'url': url,
        'params': params
      }, {root: true})
    },

    async deleteSessions({dispatch}, sessionIds) {
      const url = `/api/sessions/delete-multiple/`
      return await dispatch('network/post', {
        'url': url,
        'data': {sessionIds}
      }, {root: true})
    },

    async listTeachers({dispatch}, params) {
      const url = `/api/teachers/`
      return await dispatch('network/get', {
        'url': url,
        'params': params
      }, {root: true})
    },

    async listTeachersPayroll({dispatch}, periodId) {
      const url = `/api/teachers/payrolls/?period=${periodId}`
      return await dispatch('network/get', {
        'url': url,
      }, {root: true})
    },

    async createYear({dispatch}, yearData) {
      return await dispatch('network/post', {
        'url': '/api/years/',
        'data': yearData
      }, {root: true})
    },

    async getTeacher({dispatch}, id) {
      return await dispatch('network/get', {
        'url': `/api/teachers/${id}/`,
      }, {root: true})
    },

    async getNews({dispatch}, {params= null} = {}) {
      return await dispatch('network/get', {
        'url': '/api/web/news/',
        params
      }, {root: true})
    },

    async getProvinces({dispatch}) {
      return await dispatch('network/get', {
        'url': '/api/data/provinces/',
      }, {root: true})
    },

    async getFaq({dispatch}) {
      return await dispatch('network/get', {
        'url': '/api/web/faq/',
      }, {root: true})
    },

    async getSubscriptionMail({dispatch}, {subId, slug}) {
      return await dispatch('network/get', {
        'url': `/api/subscriptions/${subId}/get-mail/${slug}/`,
      }, {root: true})
    },

    async sendSubscriptionMail({dispatch}, {subId, slug, body}) {
      return await dispatch('network/post', {
        'url': `/api/subscriptions/${subId}/send-mail/${slug}/`,
        data: body
      }, {root: true})
    },

    async contactCoord({dispatch}, {subId, message}) {
      return await dispatch('network/post', {
        'url': `/api/subscriptions/${subId}/contact-coord/`,
        data: {message}
      }, {root: true})
    },

    async getCoordHoursForPeriod({dispatch}, periodId) {
      return await dispatch('network/get', {
        'url': `/api/coord_hours/?period=${periodId}`
      }, {root: true})
    },

    async getCoordHours({dispatch}, periodId) {
      return await dispatch('network/get', {
        'url': `/api/coords/payrolls/?period=${periodId}`
      }, {root: true})
    },

    async setCoordHours({dispatch}, data) {
      if(!data.id){
        return await dispatch('network/post', {
          'url': `/api/coord_hours/`, data
        }, {root: true})
      } else {
        return await dispatch('network/put', {
          'url': `/api/coord_hours/${data.id}/`, data
        }, {root: true})
      }
    },

    async getQuote({dispatch}, {child_id, group_id}) {
      return await dispatch('network/get', {
        'url': `/api/children/${child_id}/quote/${group_id}/`
      }, {root: true})
    },

    async updateSubscriptionDate({dispatch}, id) {
      return await dispatch('network/post', {
        'url': `/api/subscriptions/${id}/update_start_date/`,
      }, {root: true})
    },

    async sendEmailConfirmToken({dispatch}, token) {
      return await dispatch('network/post', {
        'url': '/dj-rest-auth/registration/account-confirm-email/',
        'data': {key: token}
      }, {root: true})
    },

    async listFiles({dispatch}, params) {
      const url = `/api/public_files/`
      return await dispatch('network/get', {
        'url': url,
        'params': params || {}
      }, {root: true})
    },

    async listConventions({dispatch}, params) {
      const url = `/api/conventions/`
      return await dispatch('network/get', {
        'url': url,
        'params': params || {}
      }, {root: true})
    },

    async deleteConvention({dispatch}, id) {
      const url = `/api/conventions/${id}/`
      return await dispatch('network/delete', {
        'url': url,
      }, {root: true})
    },

    async fetch({state, commit}, query) {


      let path = query.type
      let query_name = query.type

      if (query.filters) {
        let queryString = new URLSearchParams(query.filters).toString();
        path += "/?" + queryString
        console.log('prepare filters', query.filters, path);
      }

      if (query.query_name) {
        query_name = query.query_name
      }


      api_GET(path, (responseData) => {


        if (query.relations) {
          responseData.results.forEach((result, index) => {

            query.relations.forEach(relation => {

              responseData.results[index][relation] = {
                results: []
              }

              api_GET(relation + '/?' + query.relName + '=' + result.id, (relationData) => {

                commit('INSERT_RELATION', {
                  type: query.type,
                  id: result.id,
                  relation: relation,
                  relationData: relationData
                })

              })
            });
          })
        }

        state[query_name] = responseData
        state[query_name].query = query

        if (typeof query.callback === 'function') {
          query.callback(responseData);
        }
      })
    },

    reload({state, dispatch}, type) {
      dispatch('fetch', state[type].query)
    }

  },
  getters: {
    getUser(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.user !== null
    },
    isAdmin(state) {
      if (state.user === null) {
        return false
      } else {
        return countCommonValues(state.user.caps, 'admin')
      }
    },
    getYears(state) {
      return state.years
    },
    getData: (state) => (type) => {
      // console.log("getData", type, state[type]);
      if (state[type]) {
        return state[type]
      } else {
        return {
          results: []
        }
      }
    },
    levelName: (state) => (levelId) => {
      return state['data/levels'].results.find(level => level.id === levelId).name
    },
    findPeriod: (state) => (periodId) => {
      if (!state['periods']) {
        return null
      }
      return state['periods'].results.find(period => period.id === periodId);
    },
    findBranch: (state) => (branchId) => {
      if (!state['branches']) {
        return null
      }
      return state['branches'].results.find(b => b.id === branchId);
    },
    branchName: (state, getters) => (branchId) => {
      const branch = getters.findBranch(branchId)
      return branch ? branch.name : state.notAvailableTxt;
    },
  },
  modules: {
    network: network
  },
})

// Start Mirage server in development mode
//if (process.env.NODE_ENV === 'development') {
//makeServer()
//}

export default store
