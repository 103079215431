<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar class="flex ">
        <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
        />

        <q-toolbar-title>
          <span>{{ $route.meta.title || $route.name }}</span>
        </q-toolbar-title>

        <div style="width: 200px" v-show="can(['admin', 'coord']) && use_periods()">
          <q-select
              ref="periodesSelect"
              class="periodes-select"
              dark
              flat
              dense
              v-model="selected"
              :options="periods_select"
              option-value="id"
              option-label="friendly_name"
              input-debounce="0"
              label="Périodes"
              @update:model-value="update_period"
          />
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
        v-model="leftDrawerOpen"
        show-if-above
        bordered
        class="bg-grey-2"
    >
      <q-list>
        <!-- <q-item v-if="user" class="q-mt-md">
          <q-item-section avatar>
            <q-avatar>
              <img src="https://i.pravatar.cc/300">
            </q-avatar>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ user.prenom }}</q-item-label>
            <q-item-label caption>{{ user.nom }}</q-item-label>
          </q-item-section>
        </q-item> -->

        <div v-if="isLoggedIn">
          <div v-for="(item, index) in Menu" :key="index">
            <q-item :to="item.path" exact v-if="check_caps(item)"
                :disable="check_has_cap(item, 'parent') && !user.profile_completed && item.path !== '/mon-profil'">
              <q-item-section avatar>
                <q-icon :name="item.icon"/>
              </q-item-section>
              <q-item-section>
                <div>
                  <span v-html="item.name"></span>
                  <q-icon
                      v-if="check_has_cap(item, 'parent') && !user.profile_completed && item.path === '/mon-profil'"
                      name="error" color="red" class="q-ml-xs" size="1.3rem"/>
                </div>
              </q-item-section>
            </q-item>
          </div>
        </div>

        <q-item-label header>Navigation</q-item-label>

        <span v-for="(item, index) in Menu" :key="index">
					<q-item :to="item.path" exact v-if="item.caps == null">
						<q-item-section avatar>
							<q-icon :name="item.icon"/>
						</q-item-section>
						<q-item-section>
							<q-item-label v-html="item.name"></q-item-label>
						</q-item-section>
					</q-item>
				</span>

        <q-item to="/inscription" exact clickable v-if="!isLoggedIn">
          <q-item-section avatar>
            <q-icon name="key"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Inscription</q-item-label>
          </q-item-section>
        </q-item>

        <q-item @click="logout" exact clickable v-if="isLoggedIn">
          <q-item-section avatar>
            <q-icon name="logout"/>
          </q-item-section>
          <q-item-section>
            <q-item-label>Se déconnecter</q-item-label>
          </q-item-section>
        </q-item>

        <div v-if="!isLoggedIn && $route.path != '/page-protegee' && $route.path != '/connexion'">
          <q-item-label header>Connexion</q-item-label>
          <LoginForm/>
        </div>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view/>
      <MessageForm/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {ref} from 'vue'
import {mapActions, mapGetters, mapState} from 'vuex'

import Menu from 'Const/Menu'
import LoginForm from 'Comps/LoginForm'
import MessageForm from '../comps/MessageForm'

export default {
  name: 'LayoutDefault',
  data() {
    return {
      selected: null,
      selectKey: 0,
      /*options: [
        {label: '2022-2023 | 4', value: '23-4'},
        {label: '2023-2024 | 1', value: '24-1'},
        {label: '2023-2024 | 2', value: '24-2'},
        {label: '2023-2024 | 3', value: '24-3'},
        {label: '2023-2024 | 4', value: '24-4'}
      ]*/

    }
  },
  components: {
    LoginForm,
    MessageForm
  },
  setup() {
    return {
      leftDrawerOpen: ref(false),
      Menu
    }
  },
  async beforeMount() {
    if (!this.isLoggedIn) {
      await this.fetchUserData()
    }

    await this.loadGlobalData()
  },
  methods: {
    ...mapActions(['fetchUserData', 'doLogout', 'loadGlobalData']),
    async logout() {
      await this.doLogout()
      this.$router.push('/')
    },
    check_caps(item) {

      if (item.caps === null || this.user === null) {
        return false
      } else {
        let arrUserCaps = Object.values(this.user.caps)
        return this.countCommonValues(item.caps, arrUserCaps)
      }
    },
    check_has_cap(item, cap) {
      if (item.caps === null) {
        return false
      } else {
        let arrUserCaps = Object.values(item.caps)
        return arrUserCaps.includes(cap)
      }
    },
    can(caps) {

      if (this.user === null) {
        return false
      } else {
        let arrUserCaps = Object.values(this.user.caps)
        return this.countCommonValues(caps, arrUserCaps)
      }

    },
    use_periods() { //not period page
      let paths = [
        '/antennes-periodes',
        '/groupes',
        '/mon-antenne',
        '/mes-groupes',
        '/inscriptions',
        '/finances/remunerations',
      ]
      return paths.includes(this.$route.path)
    },
    countCommonValues(arr1, arr2) {
      let count = 0;
      for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
          count++;
        }
      }
      if (count > 0) {
        return true;
      } else {
        return false;
      }
    },
    update_period() {
      this.$store.state.selected_period = this.selected
      this.$store.state.selected_branche = {}
    },
    update_period_select_value() {
      setTimeout(() => {
        //this.$refs.periodesSelect.showPopup();

        this.$refs.periodesSelect.updateInputValue();
        this.$refs.periodesSelect.updateMenuPosition();
        this.$refs.periodesSelect.reset();

      }, 1000)
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLoggedIn']),
    periods_select() {
      return this.$store.getters.getData('periods').results
    },
  },
  watch: {
    '$store.state.periods.results': {
      handler(newPeriods) {
        // La valeur de this.$store.state.periods a changé
        // Faites ici les actions nécessaires en réponse au changement
        console.log('default -> periods', newPeriods, this.selected);
        /*this.$nextTick(() => {*/

        let storedSelected = localStorage.getItem('selected_period')
        if (storedSelected) {
          this.selected = JSON.parse(storedSelected)
          this.$store.state.selected_period = this.selected

          this.update_period_select_value()
        }

        if (
            (this.selected === null && newPeriods[0])
            ||
            (this.$store.state.selected_period == {} && newPeriods[0])
        ) {
          console.log('default -> cond', newPeriods);
          this.selected = newPeriods[0]
          this.$store.state.selected_period = this.selected

          this.update_period_select_value()
        } else if (newPeriods.length == 0) {
          this.selected = null
          this.$store.state.selected_period = {}
          this.update_period_select_value()
        }
        /*});*/

      },
      deep: true, // Surveille également les modifications des propriétés internes de l'objet
    },
    selected() {
      if (!this.selected) {
        localStorage.removeItem('selected_period')
      } else {
        localStorage.setItem('selected_period', JSON.stringify(this.selected))
      }
    }
    // '$store.state.user': {
    // 	handler(user) {
    // 		console.log('user log', user);
    // 		this.load_global_data()
    // 	},
    // 	deep: true, // Surveille également les modifications des propriétés internes de l'objet
    // },
  },

}
</script>

<style type="text/css">
.periodes-select .q-field__native {
  max-height: 32px !important;
}
</style>
